import React from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

const CompanyStressScatterChart = ({ groups }) => {
  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart
      const midX = x.getPixelForValue(0)
      const midY = y.getPixelForValue(0)
      ctx.save()
      const high_top = y.getPixelForValue(0);
      const high_height = y.getPixelForValue(12) - high_top
      const low_top = y.getPixelForValue(12)
      const low_height = y.getPixelForValue(17) - low_top
      const left2 = x.getPixelForValue(26)
      const right2 = (midX - left) - (left2 - left)

      ctx.fillStyle = "rgba(255, 0, 100, 0.2)";
      ctx.fillRect(left, high_top, x.width, high_height);

      ctx.fillStyle = "rgba(255, 0, 100, 0.2)";
      ctx.fillRect(left2, low_top, right2, low_height);

      ctx.fillStyle = "#2d382f"
      ctx.font = "bold 10px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("準高反応・高要因群", x.getPixelForValue(5), low_top + low_height / 2);

      ctx.fillStyle = "#2d382f"
      ctx.font = "bold 10px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("高反応・高要因群", x.getPixelForValue(5), y.getPixelForValue(5));

      ctx.fillStyle = "#2d382f"
      ctx.font = "bold 10px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("高反応群", x.getPixelForValue(53), y.getPixelForValue(5));

      ctx.restore();
    }
  }

  const options = {
    responsive: true,
    //maintainAspectRatio: false,
    //aspectRatio: 50,
    scales: {
      y: {
        title: {
          display: true,
          text: 'ストレス反応',
        },
        max: 30,
        min: 0,
        reverse: true,
      },
      x: {
        title: {
          display: true,
          text: 'ストレス要因・周囲のサポート',
        },
        max: 57,
        min: 0,
        reverse: true,
      },
    },
    plugins: {
      quadrants: {
        bottomRight: '#ff686882',
        bottomLeft: '#ff686882',
      },
      legend: {
        display: false,
      },
    }
  }

  const plugins = [quadrants]

  const colors = [
    'rgba(255, 0, 0, 0.5)',
    'rgba(0, 0, 255, 0.5)',
    'rgba(0, 255, 0, 0.5)',
    'rgba(255, 255, 0, 0.5)',
    'rgba(255, 0, 255, 0.5)',
    'rgba(0, 255, 255, 0.5)',
    'rgba(255, 165, 0, 0.5)',
    'rgba(255, 192, 203, 0.5)',
    'rgba(139, 69, 19, 0.5)',
    'rgba(128, 128, 128, 0.5)',
  ]

  const data = {datasets: []}
  Object.values(groups).forEach((group, index) => {
    const dataObj = {
      label: group[0].groupName,
      animation: false,
      data: [],
      // 色はindexによって変える
      // indexが10以上の場合は、10で割った余りを使う
      backgroundColor: colors[index % 10],
    }
    group.forEach((scores, index) => {
      const obj = {
        x: scores.supportScore,
        y: scores.stressScore,
      }
      dataObj.data.push(obj)
    })
    data.datasets.push(dataObj)
  })

  return <Scatter options={options} data={data} plugins={plugins} />
}

export default CompanyStressScatterChart
