import React, { useState, useRef, useEffect } from 'react'
import SplitPane from 'react-split-pane'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { SlCalender } from "react-icons/sl"
import { LuClipboardEdit } from "react-icons/lu"
import { FaPencilAlt, FaPlusCircle, FaUser, FaCheckCircle } from "react-icons/fa"
import { FaUserDoctor, FaCalendarCheck, FaBuilding } from "react-icons/fa6"
import { IoMail, IoCloudUploadSharp } from "react-icons/io5";
import { HiOutlineSave } from "react-icons/hi"
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TbChartInfographic, TbExternalLink, TbReportAnalytics } from "react-icons/tb"
import { FaFileImage } from "react-icons/fa6"
import { PiClipboardFill } from "react-icons/pi"
import { MdOutlineSentimentVerySatisfied } from "react-icons/md";
import { IoMdAlert } from "react-icons/io";
import { RiMentalHealthFill } from "react-icons/ri";

import axios from 'axios'
import dayjs from 'dayjs'

const csrfToken = document.querySelector('[name=csrf-token]').content

const CounselorKarute = ({ counselingReserveJson, pastCounselingReservesJson, userJson }) => {
  const counselingReserve = JSON.parse(counselingReserveJson)
  const pastCounselingReserves = JSON.parse(pastCounselingReservesJson)
  const user = JSON.parse(userJson)

  const soapTextTemplate = `【S】
---------------------------------------


【O】
---------------------------------------


【A】
---------------------------------------


【P】
---------------------------------------


その他
---------------------------------------


`

  const [editorValue, setEditorValue] = useState(counselingReserve.karute_text || soapTextTemplate)
  const [toggles, setToggles] = useState([])
  const [isKaruteCompleted, setIsKaruteCompleted] = useState(counselingReserve.is_karute_completed)
  const [files, setFiles] = useState(counselingReserve.documents)
  const [fileFields, setFileFields] = useState([])
  const [jobPoint, setJobPoint] = useState(counselingReserve.job_point)
  const [privatePoint, setPrivatePoint] = useState(counselingReserve.private_point)
  const [healthPoint, setHealthPoint] = useState(counselingReserve.health_point)
  const [satisfactionLevel, setSatisfactionLevel] = useState(counselingReserve.satisfaction_level)
  const [importanceLevel, setImportanceLevel] = useState(counselingReserve.importance_level)
  const [remindDays, setRemindDays] = useState(counselingReserve.remind_days)
  const [remindDate, setRemindDate] = useState(dayjs(counselingReserve.start_at).add(remindDays, 'day').format('YYYY年MM月DD日'))

  const saveRef = useRef(null)
  const saveErrorRef = useRef(null)
  const fileGetErrorRef = useRef(null)
  const completeRef = useRef(null)
  const fileUploadCompleteRef = useRef(null)
  const fileDeleteCompleteRef = useRef(null)
  const copyRef = useRef(null)
  const loadingRef = useRef(null)
  const categoryErrorRef = useRef(null)

  useEffect(() => {
    const newToggles = Object.values(pastCounselingReserves).map((reserve, index) => {
      return {
        id: index + 1,
        startAt: dayjs(reserve.start_at).format('YYYY年MM月DD日 HH:mm'),
        counselorName: reserve.counselor_name,
        text: reserve.karute_text,
        isOn: false
      }
    })
    setToggles(newToggles)
  }, [])

  const showLoading = () => {
    loadingRef.current.classList.remove('hidden')
  }

  const hideLoading = () => {
    loadingRef.current.classList.add('hidden')
  }

  const initFileFields = () => {
    showLoading()
    setFileFields([])

    axios.get(`/counselors/api/counseling_reserves/${counselingReserve.id}`, {
      headers: { 'X-CSRF-Token': csrfToken }
    })
      .then(response => {
        console.log(response.data)
        setFiles(response.data.documents)
      })
      .catch(error => {
        fileGetErrorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          fileGetErrorRef.current.classList.remove('opacity-100');
        }, 1000);
      })
      .finally(() => {
        hideLoading()
      })
  }

  const handleToggle = (id) => {
    setToggles(toggles.map(item =>
      item.id === id ? { ...item, isOn: !item.isOn } : item
    ))
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        copyRef.current.classList.add('opacity-100');
        setTimeout(() => {
          copyRef.current.classList.remove('opacity-100');
        }, 1000);
      })
      .catch(() => {
        errorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          errorRef.current.classList.remove('opacity-100');
        }, 1000);
      })
  }

  const handleAddFile = (e, id) => {
    const newFileFields = fileFields.map(field =>
      field.id === id ? { id: id, file: e.target.files[0] } : field
    )
    setFileFields(newFileFields)
  }

  const handleAddFileField = () => {
    const newId = fileFields.length > 0 ? fileFields[fileFields.length - 1].id + 1 : 1
    setFileFields([...fileFields, { id: newId, file: null }])
  }

  const handleJobPoint = (e) => {
    setJobPoint(e.target.value)
  }

  const handlePrivatePoint = (e) => {
    setPrivatePoint(e.target.value)
  }

  const handleHealthPoint = (e) => {
    setHealthPoint(e.target.value)
  }

  const handleSatisfactionLevel = (e) => {
    setSatisfactionLevel(e.target.value)
  }

  const handleImportanceLevel = (e) => {
    setImportanceLevel(e.target.value)
  }

  const handleRemindDays = (e) => {
    setRemindDays(e.target.value)
    setRemindDate(dayjs(counselingReserve.start_at).add(e.target.value, 'day').format('YYYY年MM月DD日'))
  }

  const handleDeleteFile = (documentId) => {
    if (!window.confirm('ファイルを削除しますか？')) {
      return;
    }

    showLoading()

    axios.delete(`/counselors/api/counseling_reserves/${counselingReserve.id}/documents/${documentId}`, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
    .then(response => {
      if (response.status === 200) {
        fileDeleteCompleteRef.current.classList.add('opacity-100');
        setTimeout(() => {
          fileDeleteCompleteRef.current.classList.remove('opacity-100');
        }, 1000);
      } else {
        saveErrorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          saveErrorRef.current.classList.remove('opacity-100');
        }, 1000);
      }
    })
    .catch(error => {
      saveErrorRef.current.classList.add('opacity-100');
      setTimeout(() => {
        saveErrorRef.current.classList.remove('opacity-100');
      }, 1000);
    })
    .finally(() => {
      hideLoading()
      initFileFields();
    })
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    fileFields.forEach(field => {
      if (!field.file) {
        return;
      }
      formData.append('documents[]', field.file);
    });

    showLoading()
    axios.patch(`/counselors/api/counseling_reserves/${counselingReserve.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': csrfToken
      }
    })
    .then(response => {
      if (response.status === 200) {
        fileUploadCompleteRef.current.classList.add('opacity-100');
        setTimeout(() => {
          fileUploadCompleteRef.current.classList.remove('opacity-100');
        }, 1000);
      } else {
        saveErrorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          saveErrorRef.current.classList.remove('opacity-100');
        }, 1000);
      }
    })
    .catch(error => {
      saveErrorRef.current.classList.add('opacity-100');
      setTimeout(() => {
        saveErrorRef.current.classList.remove('opacity-100');
      }, 1000);
    })
    .finally(() => {
      hideLoading()
      initFileFields();
    })
  };

  const onHandleSave = () => {
    showLoading()
    axios.patch(`/counselors/api/counseling_reserves/${counselingReserve.id}`, 
      {
        karute_text: editorValue,
        job_point: jobPoint,
        private_point: privatePoint,
        health_point: healthPoint,
        satisfaction_level: satisfactionLevel,
        importance_level: importanceLevel,
        remind_days: remindDays,
      },
      { headers: { 'X-CSRF-Token': csrfToken } }
    )
      .then(response => {
        if (response.status === 200) {
          saveRef.current.classList.add('opacity-100');
          setTimeout(() => {
            saveRef.current.classList.remove('opacity-100');
          }, 1000);
        } else {
          saveErrorRef.current.classList.add('opacity-100');
          setTimeout(() => {
            saveErrorRef.current.classList.remove('opacity-100');
          }, 1000);
        }
      })
      .catch(error => {
        // Handle error
        saveErrorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          saveErrorRef.current.classList.remove('opacity-100');
        }, 1000);
      })
      .finally(() => {
        hideLoading()
      })
  }

  const isCategoryTotalTen = () => {
    return parseInt(jobPoint) + parseInt(privatePoint) + parseInt(healthPoint) === 10
  }

  const onHandleKaruteComplete = () => {
    if (!isCategoryTotalTen()) {
      categoryErrorRef.current.classList.add('opacity-100');
      setTimeout(() => {
        categoryErrorRef.current.classList.remove('opacity-100');
      }
      , 1000);
      return;
    }

    if (!window.confirm('カルテの記入を完了しますか？完了後は編集できません')) {
      return;
    }

    showLoading()
    axios.patch(`/counselors/api/counseling_reserves/${counselingReserve.id}`,
      {
        is_karute_completed: true,
        manual_edit_flg: false,
        karute_text: editorValue,
        job_point: jobPoint,
        private_point: privatePoint,
        health_point: healthPoint,
        satisfaction_level: satisfactionLevel,
        importance_level: importanceLevel,
        remind_days: remindDays,
      },
      { headers: { 'X-CSRF-Token': csrfToken } }
    )
      .then(response => {
        if (response.status === 200) {
          completeRef.current.classList.add('opacity-100');
          setIsKaruteCompleted(true);
          setTimeout(() => {
            completeRef.current.classList.remove('opacity-100');
          }, 1000);
        } else {
          saveErrorRef.current.classList.add('opacity-100');
          setTimeout(() => {
            saveErrorRef.current.classList.remove('opacity-100');
          }, 1000);
        }
      })
      .catch(error => {
        // Handle error
        console.log(error);
        saveErrorRef.current.classList.add('opacity-100');
        setTimeout(() => {
          saveErrorRef.current.classList.remove('opacity-100');
        }, 1000);
      })
      .finally(() => {
        hideLoading()
      })
  }

  // Resizerスタイルの定義
  const resizerStyle = {
    background: '#666',
    opacity: '0.5',
    zIndex: '10',
    width: '8px',
    cursor: 'col-resize',
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
  }

  const quillStyle = {
    height: '60%',
    scrollY: 'auto',
    fontSize: '18px',
  }

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const quillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link'
  ]

  return (
    <div className="flex h-screen max-w-full">
      <div className="flex justify-center w-full">
        <div className="fixed top-2 px-12 bg-green-500 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={saveRef}>
          一時保存しました
        </div>
        <div className="fixed top-2 px-12 bg-yellow-500 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={completeRef}>
          カルテの記入が完了しました
        </div>
        <div className="fixed top-2 px-12 bg-red-400 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={saveErrorRef}>
          エラーが発生しました
        </div>
        <div className="fixed top-2 px-12 bg-red-400 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={fileGetErrorRef}>
          ファイル取得に失敗しました
        </div>
        <div className="fixed top-2 px-12 bg-red-400 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={categoryErrorRef}>
          カテゴリの合計が10になっていません
        </div>
        <div className="fixed top-2 px-12 bg-blue-400 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={copyRef}>
          コピーしました
        </div>
        <div className="fixed top-2 px-12 bg-green-500 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={fileDeleteCompleteRef}>
          ファイルを削除しました
        </div>
        <div className="fixed top-2 px-12 bg-green-500 text-white rounded-lg py-2 text-base z-50 duration-700 opacity-0" ref={fileUploadCompleteRef}>
          ファイルをアップロードが完了しました
        </div>
      </div>

      <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50 flex justify-center items-center hidden" ref={loadingRef}>
        <div className="text-center animate-spin text-white text-7xl">
          <AiOutlineLoading3Quarters />
        </div>
      </div>

      <SplitPane
        split="vertical"
        defaultSize="30%"
        resizerStyle={resizerStyle}
      >
        <div className="overflow-auto w-full h-full bg-white">
          <div className="bg-gray-100">
            <div className="border-b-2 border-r-2 border-gray-200 inline-block">
              <div className="bg-white text-base font-bold px-4 py-2 flex items-center border-t-2 border-green-500">
                <div className="mr-2 text-green-500">
                  <SlCalender />
                </div>
                <div>
                  カルテ一覧
                </div>
              </div>
            </div>
          </div>
          <ul className="flex justify-content flex-wrap p-2">
            {toggles.map(toggle => (
              <li key={toggle.id} className="w-full cursor-pointer">
                <div className="mb-4 bg-white">
                  <div className="w-full" onClick={() => handleToggle(toggle.id)}>
                    <div className="w-full">
                      <div className="bg-gray-100 text-sm py-2 px-4 break-label">
                        <div className="flex items-center justify-between">
                          <div>
                            <span className="text-xs mr-2 text-gray-400">{toggle.isOn ? '▼' : '▶'}</span>
                            <span className="text-base">{toggle.startAt}</span>
                          </div>

                          <div className="text-sm flex items-center px-4">
                            <div className="mr-2 text-gray-400">
                              <FaUserDoctor />
                            </div>
                            カウンセラー：<span className="text-gray-700 font-bold">{toggle.counselorName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {toggle.isOn &&
                    <div className="text-sm border-2 border-gray-200">
                      <div className="p-2 text-sm border-b-2 border-gray-200">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="mr-2 text-gray-400">
                              <LuClipboardEdit />
                            </div>
                            診療記録
                          </div>
                          <div className="">
                            <button className="bg-gray-400 p-2 rounded-lg text-white" onClick={() => handleCopy(toggle.text)}>
                              <PiClipboardFill />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="p-2 text-sm">
                        {(toggle.text && toggle.text.includes('\n')) ? (
                          <>
                            {toggle.text.split('\n').map((line, index) => (
                              <>
                                {(line && line.length <= 1) ? (
                                  <div key={index} className="pb-2"></div>
                                ) : (
                                  <div key={index} className="pb-1">
                                    {line}
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          toggle.text
                        )}
                      </div>
                    </div>
                  }

                </div>
              </li>
            ))}
          </ul>
          <div className="border-b-2 border-gray-200">
            <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
              <div className="bg-white text-base font-bold flex items-center">
                <div className="mr-2 text-green-500">
                  <SlCalender />
                </div>
                <div className="text-lg">
                  過去のカルテ
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 mb-4">
            <div className="text-sm border-2 border-gray-200">
              <div className="p-2 text-sm border-b-2 border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="mr-2 text-gray-400">
                      <LuClipboardEdit />
                    </div>
                    診療記録
                  </div>
                  <div className="">
                    <button className="bg-gray-400 p-2 rounded-lg text-white" onClick={() => handleCopy(user.past_karute_text)}>
                      <PiClipboardFill />
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-4 text-sm bg-gray-100">
                {(user.past_karute_text && user.past_karute_text.includes('\n')) ? (
                  <>
                    {user.past_karute_text.split('\n').map((line, index) => (
                      <>
                        {(line && line.length <= 1) ? (
                          <div key={index} className="pb-2"></div>
                        ) : (
                          <div key={index} className="pb-1">
                            {line}
                          </div>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  user.past_karute_text
                )}
              </div>
            </div>
          </div>
        </div>
        <SplitPane
          split="vertical"
          defaultSize="70%"
          resizerStyle={resizerStyle}
        >
          <div className="overflow-auto w-full h-full bg-white">
            <div className="border-b-2 border-gray-200">
              <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                <div className="bg-white text-base flex items-center">
                  <div className="mr-2 text-green-500">
                    <FaPencilAlt />
                  </div>
                  <div className="text-sm">
                    カウンセリング日時：<span className="text-lg font-bold">{dayjs(counselingReserve.start_at).format('YYYY年MM月DD日 HH:mm')}</span>
                  </div>
                </div>
                <div className="text-sm flex items-center">
                  <div className="mr-2 text-gray-400">
                    <FaUserDoctor />
                  </div>
                  カウンセラー：<span className="text-green-500 font-bold">{counselingReserve.counselor_name}</span>
                </div>
              </div>
            </div>

            {/* 中央カラムのWYSIWYGエディタ */}
            {/*<ReactQuill theme="snow"
              value={editorValue}
              onChange={setEditorValue}
              style={quillStyle}
              modules={quillModules}
              formats={quillFormats}
            />*/}
            <textarea
              value={editorValue}
              onChange={(e) => setEditorValue(e.target.value)}
              className="w-full h-3/5 py-8 px-10 bg-gray-100 text-sm leading-6"
            ></textarea>

            <div className="border-b-2 border-gray-200">
              <div className="border-b-2 border-gray-200">
                <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                  <div className="bg-white text-base font-bold flex items-center">
                    <div className="mr-2 text-green-500">
                      <TbChartInfographic />
                    </div>
                    <div className="text-lg">
                      カテゴリ<span className="text-sm text-gray-500">（合計10になるように設定してください）</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-sm flex items-center justify-between bg-gray-200">
                <div className="mr-2 flex items-center w-1/3 justify-center p-2">
                  <div className="font-bold w-2/3 p-2 text-base flex items-center">
                    <div className="mr-2 text-gray-400">
                      <FaBuilding />
                    </div>
                    仕事
                  </div>
                  <select value={jobPoint} onChange={handleJobPoint} className="w-1/3 p-2 text-base rounded-lg">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
                <div className="mr-2 flex items-center w-1/3 justify-center p-2 border-l-2 border-gray-300">
                  <div className="font-bold w-2/3 p-2 text-base flex items-center">
                    <div className="mr-2 text-gray-400">
                      <FaUser />
                    </div>
                    プライベート
                  </div>
                  <select value={privatePoint} onChange={handlePrivatePoint} className="w-1/3 text-base p-2 rounded-lg">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
                <div className="mr-2 flex items-center w-1/3 justify-center p-2 border-l-2 border-gray-300">
                  <div className="font-bold w-2/3 p-2 text-base flex items-center">
                    <div className="mr-2 text-gray-400">
                      <RiMentalHealthFill />
                    </div>
                    健康
                  </div>
                  <select value={healthPoint} onChange={handleHealthPoint} className="w-1/3 text-base p-2 rounded-lg">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="text-sm flex items-center p-2">
                合計：<span className={`text-xl font-bold ${!isCategoryTotalTen() ? 'text-red-500' : 'text-green-600'}`}>{parseInt(jobPoint) + parseInt(privatePoint) + parseInt(healthPoint)}</span>
              </div>
            </div>

            <div className="flex">
              <div className="w-1/2">
                <div className="border-b-2 border-gray-200">
                  <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                    <div className="bg-white text-base font-bold flex items-center">
                      <div className="mr-2 text-green-500">
                        <IoMdAlert />
                      </div>
                      <div className="text-lg">
                        重要度<span className="text-sm text-gray-500">（1:低い, 5:高い）</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-sm flex items-center justify-between bg-gray-200">
                  <div className="mr-2 flex items-center w-full justify-center p-2">
                    <select value={importanceLevel} onChange={handleImportanceLevel} className="w-full p-2 text-base rounded-lg">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-1/2 border-l-2 border-green-500">
                <div className="border-b-2 border-gray-200">
                  <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                    <div className="bg-white text-base font-bold flex items-center">
                      <div className="mr-2 text-green-500">
                        <MdOutlineSentimentVerySatisfied />
                      </div>
                      <div className="text-lg">
                        満足度<span className="text-sm text-gray-500">（1:低い, 5:高い）</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-sm flex items-center justify-between bg-gray-200">
                  <div className="mr-2 flex items-center w-full justify-center p-2">
                    <select value={satisfactionLevel} onChange={handleSatisfactionLevel} className="w-full p-2 text-base rounded-lg">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-b-2 border-gray-200">
              <div className="border-b-2 border-gray-200">
                <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                  <div className="bg-white text-base font-bold flex items-center">
                    <div className="mr-2 text-green-500">
                      <FaCalendarCheck />
                    </div>
                    <div className="text-lg">
                      リマインド日数<span className="text-sm text-gray-500">（カウンセリング実施日からの日数）</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-sm flex items-center justify-between bg-gray-200">
                <div className="flex w-full p-2 items-center">
                  <input type="number" value={remindDays} onChange={handleRemindDays} min="0" max="365" className="w-26 p-2 text-base mr-2" />日後
                </div>
              </div>
              <div className="text-sm flex items-center p-2 border-t-2 border-gray-200 border-b-2">
                リマインド実施日：<span className="font-bold text-base text-gray-900">{remindDate}</span>
              </div>
            </div>

            <div className="px-4 pb-4 border-b-2 border-gray-200 mt-4">
              <div className="flex justify-end">
                {!isKaruteCompleted && (
                  <>
                    <button onClick={() => onHandleSave()} className="mt-2 py-2 px-12 text-sm bg-green-500 border-b-2 border-green-600 font-bold text-white rounded-xl mr-2 flex items-center cursor-pointer">
                      <div className="mr-2">
                        <HiOutlineSave />
                      </div>
                      <span className="text-sm">一時保存</span>
                    </button>
                    <button onClick={() => onHandleKaruteComplete()} className="mt-2 py-2 px-12 text-sm bg-yellow-500 border-b-2 border-yellow-600 font-bold text-white rounded-xl flex items-center cursor-pointer">
                      <div className="mr-2">
                        <FaCheckCircle />
                      </div>
                      <span className="text-sm">完了</span>
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="border-b-2 border-gray-200">
              <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                <div className="bg-white text-base font-bold flex items-center">
                  <div className="mr-2 text-green-500">
                    <TbReportAnalytics />
                  </div>
                  <div className="text-lg">
                    法人への報告フォームURL
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-4 flex items-center">
              <div className="mr-2 text-gray-500">
                <TbExternalLink />
              </div>
              <a href="https://forms.gle/UjN6J4MUmkduQ5on8" className="underline text-blue-500" target="_blank">
                https://forms.gle/UjN6J4MUmkduQ5on8
              </a>
            </div>

            <div className="border-b-2 border-gray-200">
              <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                <div className="bg-white text-base font-bold flex items-center">
                  <div className="mr-2 text-green-500">
                    <FaFileImage />
                  </div>
                  <div className="text-lg">
                    ファイルアップロード<span className="text-sm text-gray-500">（画像、PDF、10MBまで）</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="w-full">
                {files.map(file => (
                  <div key={file.id} className="border-2 border-gray-200 p-2 text-sm">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="mr-2 text-gray-400">
                          <FaFileImage />
                        </div>
                        <div>
                          {file.filename.match(/.jpg|.jpeg|.png|.gif/) && (
                            <a href={file.url} target="_blank" className="text-sm underline text-blue-500 block">
                              <img src={file.url} className="w-32" />
                            </a>
                          )}
                          <a href={file.url} className="text-sm underline text-blue-500" target="_blank">
                            {file.filename}
                          </a>
                        </div>
                      </div>
                      {!isKaruteCompleted && (
                        <div className="ml-2">
                          <button onClick={() => handleDeleteFile(file.id)} className="underline text-red-500 text-sm">
                            × 削除
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                {fileFields.map(field => (
                  <div key={field.id} className="mb-2 text-sm mt-4">
                    <input type="file" onChange={(e) => handleAddFile(e, field.id)} accept="image/*,.pdf, image/png, image/jpeg, image/jpg, application/pdf" />
                  </div>
                ))}
              </div>

              {!isKaruteCompleted && (
                <div className="mt-4">
                  <div className="flex justify-end">
                    <button onClick={handleAddFileField} className="mt-2 py-2 px-12 text-sm bg-green-500 border-b-2 border-green-600 font-bold text-white rounded-xl mr-2 flex items-center cursor-pointer">
                      <div className="mr-2">
                        <FaPlusCircle />
                      </div>
                      <span className="text-sm">
                        ファイル追加
                      </span>
                    </button>

                    <button onClick={handleFileUpload} className="mt-2 py-2 px-12 text-sm bg-yellow-500 border-b-2 border-yellow-600 font-bold text-white rounded-xl flex items-center cursor-pointer">
                      <div className="mr-2">
                        <IoCloudUploadSharp />
                      </div>
                      <span className="text-sm">
                        アップロード
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="overflow-auto w-full h-full bg-white">
            {/* 右カラムのコンテンツ */}
            <div className="p-2">
              <div className="bg-white">
                <div className="w-full">
                  <div className="w-full">
                    <div className="bg-gray-100 text-sm py-2 px-4 break-label">
                      <div className="flex items-center">
                        <span className="text-sm mr-2 text-gray-400">
                          <FaUser />
                        </span>
                        <span className="text-sm font-bold">
                          クライエント情報
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-2 border-gray-200 p-2 text-sm">
                <p className="text-sm text-gray-500 border-b-2 border-gray-200">
                  お名前
                </p>
                <p className="font-bold mt-2">
                  {user.name}
                </p>

                <p className="text-sm text-gray-500 border-b-2 border-gray-200 mt-4">
                  性別
                </p>
                <p className="font-bold mt-2">
                  {user.gender_name}
                </p>

                <p className="text-sm text-gray-500 border-b-2 border-gray-200 mt-4">
                  年齢
                </p>
                <p className="font-bold mt-2">
                  {user.age}歳
                </p>
              </div>
            </div>

            <div className="border-b-2 border-gray-200 mt-2">
              <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                <div className="bg-white text-base font-bold flex items-center">
                  <div className="mr-2 text-green-500">
                    <TbReportAnalytics />
                  </div>
                  <div className="text-lg">
                    相談内容
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="bg-gray-200 p-4 text-sm">
                {(counselingReserve.user_comment && counselingReserve.user_comment.includes('\n')) ? (
                  <>
                    {counselingReserve.user_comment.split('\n').map((line, index) => (
                      <>
                        {(line && line.length <= 1) ? (
                          <div key={index} className="pb-2"></div>
                        ) : (
                          <div key={index} className="pb-1">
                            {line}
                          </div>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  counselingReserve.user_comment || '無し'
                )}
              </div>
            </div>

            <div className="border-b-2 border-gray-200 mt-2">
              <div className="flex justify-between border-t-2 border-green-500 px-4 py-2">
                <div className="bg-white text-base font-bold flex items-center">
                  <div className="mr-2 text-green-500">
                    <TbReportAnalytics />
                  </div>
                  <div className="text-lg">
                    クライエント申送事項
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="bg-gray-200 p-4 text-sm">
                {(user.matter_text && user.matter_text.includes('\n')) ? (
                  <>
                    {user.matter_text.split('\n').map((line, index) => (
                      <>
                        {(line && line.length <= 1) ? (
                          <div key={index} className="pb-2"></div>
                        ) : (
                          <div key={index} className="pb-1">
                            {line}
                          </div>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  user.matter_text || '無し'
                )}
              </div>
            </div>

            {user.company_name && (
              <div className="border-b-2 border-gray-200 mt-2">
                <div className="border-b-2 border-gray-200 mt-2">
                  <div className="flex justify-between border-t-2 border-green-600 px-4 py-2">
                    <div className="bg-white text-base font-bold flex items-center">
                      <div className="mr-2 text-green-600">
                        <FaBuilding />
                      </div>
                      <div className="text-lg">
                        団体
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-2">
                  <div className="bg-white">
                    <div className="border-2 border-gray-200 p-2 text-sm">
                      <p className="text-sm text-gray-500 border-b-2 border-gray-200">
                        所属団体
                      </p>
                      <p className="font-bold mt-2">
                        {user.company_name}
                      </p>
                      <p className="text-sm text-gray-500 border-b-2 border-gray-200 mt-4">
                        都道府県
                      </p>
                      <p className="font-bold mt-2">
                        {user.company_prefecture_name}
                      </p>
                    </div>
                  </div>
                  <div className="bg-gray-200 p-4 text-sm mt-2">
                    {(user.company_memo && user.company_memo.includes('\n')) ? (
                      <>
                        {user.company_memo.split('\n').map((line, index) => (
                          <>
                            {(line && line.length <= 1) ? (
                              <div key={index} className="pb-2"></div>
                            ) : (
                              <div key={index} className="pb-1">
                                {line}
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      user.company_memo || '無し'
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="">
              <div className="w-full p-2 py-4">
                {!user.company_name && (
                  <a href={`/counselors/my_page/next_reserve_mails/${user.id}`} className="p-2 text-base bg-yellow-500 border-b-2 border-yellow-600 font-bold text-white rounded-xl flex items-center cursor-pointer w-full justify-center">
                    <div className="mr-2">
                      <IoMail />
                    </div>
                    <span>
                      次回予約メール
                    </span>
                  </a>
                )}

                {user.company_name && (
                  <a href={`/counselors/my_page/next_reserves/${user.id}`} className="p-2 text-base bg-yellow-500 border-b-2 border-yellow-600 font-bold text-white rounded-xl flex items-center cursor-pointer w-full justify-center">
                    <div className="mr-2">
                      <FaCalendarCheck />
                    </div>
                    <span>
                      次回予約
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </SplitPane>
      </SplitPane>
    </div>
  )
}

export default CounselorKarute
