import React from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'

let bg_image = new Image()
bg_image.src = '/images/stress_check_bg02.png'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

const CompanyStressCheckWorkChart = ({ avgBossSupportScore, avgCoworkerSupportScore }) => {
  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      // 画像を描画
      const ctx = chart.ctx
      const xPos = 49
      const yPos = 10
      ctx.drawImage(bg_image, xPos, yPos, 291, 290)
    }
  }

  console.log('avgBossSupportScore', avgBossSupportScore)
  console.log('avgCoworkerSupportScore', avgCoworkerSupportScore)
  const data = {datasets: []}
  const dataObj = {
    data: [
      {
        x: avgBossSupportScore, // 上司の支援
        y: avgCoworkerSupportScore, // 同僚の支援
      }
    ],
    backgroundColor: 'rgba(255, 0, 0, 0.5)',
    pointRadius: 6,
  }
  data.datasets.push(dataObj)

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    aspectRatio: 50,
    bounds: 'data',
    scales: {
      y: {
        title: {
          display: true,
          text: '同僚の支援',
        },
        max: 11,
        min: 5,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false
        }
      },
      x: {
        title: {
          display: true,
          text: '上司の支援',
        },
        max: 10,
        min: 4,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false
        }
      },
    },
    plugins: {
      quadrants: {
        bottomRight: '#ff686882',
        bottomLeft: '#ff686882',
      },
      legend: {
        display: false,
      },
    }
  }

  const plugins = [quadrants]

  return <Scatter options={options} data={data} plugins={plugins} width={350} height={350} />
}

export default CompanyStressCheckWorkChart
